import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/block-testimonial.module.scss"
import cardStyles from "src/styles/components/card.module.scss"
import Grid from "@material-ui/core/Grid"
import cx from "classnames"

export default function BlockTestimonial({ testimonial }) {
  const {
    review,
    reviewer,
    company,
    reviewer_photo,
    reviewer_company_logo,
  } = testimonial

  return (
    <Grid container item xs={12} sm={6}>
      <Grid
        container
        item
        xs={12}
        className={cx(cardStyles.cardContainer, styles.root)}
      >
        <Grid item xs={12} className={styles.reviewText}>
          <p>{review}</p>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={4}
          className={styles.reviewFooter}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <img
            src={reviewer_photo}
            alt="reviewer"
            className={styles.reviewerPhoto}
          />
          <Grid
            item
            className={styles.reviewDetails}
            alignContent="space-between"
          >
            <Grid item xs={12}>
              {reviewer}
            </Grid>
            <Grid item xs={12}>
              {company}
            </Grid>
            <Grid item xs={12}>
              <img
                src={reviewer_company_logo}
                alt="reviewer company logo"
                className={styles.reviewerCompanyLogo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

BlockTestimonial.propTypes = {
  testimonial: PropTypes.shape({
    review: PropTypes.string,
    reviewer: PropTypes.string,
    company: PropTypes.string,
    reviewer_photo: PropTypes.string,
    reviewer_company_logo: PropTypes.string,
  }),
}
