import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/block-partner.module.scss"
import Grid from "@material-ui/core/Grid"

export default function BlockPartner({ partner }) {
  const { link, name, logo } = partner

  return (
    <Grid item xs={6} sm={3} justify="center" alignItems="center">
      <div className={styles.logoContainer}>
        <a href={link}>
          <img
            className={styles.logo}
            src={logo}
            alt={name}
            title={name}
            key={name}
          />
        </a>
      </div>
    </Grid>
  )
}

BlockPartner.propTypes = {
  partner: PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
}
