import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import map from "lodash/map"
import Grid from "@material-ui/core/Grid"
import styles from "src/styles/components/section.module.scss"
import typography from "src/styles/typography.module.scss"
import BlockPartner from "./block-partner"
import BlockFeature from "./block-feature"
import BlockTestimonial from "./block-testimonial"
import Jumbotron from "./jumbotron"

function renderBlock(block, section, sectionIndex, blockIndex) {
  switch (block.template) {
    case "partner": {
      return <BlockPartner key={`block-${blockIndex}`} partner={block} />
    }
    case "feature": {
      return (
        <BlockFeature
          key={`block-${blockIndex}`}
          card={section.color_scheme === "white"}
          feature={block}
          index={sectionIndex}
        />
      )
    }
    case "testimonial": {
      return (
        <BlockTestimonial key={`block-${blockIndex}`} testimonial={block} />
      )
    }
    default:
      return null
  }
}

function renderRegularSection(section, children = [], sectionIndex) {
  const {
    title,
    title_size,
    image,
    color_scheme,
    description,
    cta_text,
    cta_link,
    blocks,
  } = section

  return (
    <Grid
      component="section"
      className={cx({
        [styles.root]: true,
        [styles.colored]: color_scheme === "colored",
        [styles.white]: color_scheme === "white",
        [styles.filled]: color_scheme === "filled",
      })}
    >
      <Grid item xs={12}>
        <div className={styles.title}>
          <h1
            className={cx({
              [typography.small]: title_size === "small",
              [typography.medium]: title_size === "medium",
            })}
          >
            {title}
          </h1>
        </div>
      </Grid>
      {description && (
        <Grid item xs={12}>
          <p className={cx(styles.description)}>{description}</p>
        </Grid>
      )}
      {cta_link && (
        <Grid item xs={12}>
          <a href={cta_link}>
            <button>{cta_text}</button>
          </a>
        </Grid>
      )}
      {image && (
        <Grid item xs={12}>
          <img alt="title" className={styles.image} src={image} />
        </Grid>
      )}
      {blocks && (
        <Grid
          className={cx(styles.blocks, styles.boundedWidth)}
          xs={12}
          spacing={4}
          item
          container
          alignItems="stretch"
          alignContent="center"
          justify="center"
        >
          {map(blocks, (block, blockIndex) =>
            renderBlock(block, section, sectionIndex, blockIndex)
          )}
        </Grid>
      )}
      {children}
    </Grid>
  )
}

export default function Section(props) {
  const { section = {}, index, children } = props
  switch (section.type) {
    case "jumbotron":
      return <Jumbotron section={section} />
    default:
      return renderRegularSection(section, children, index)
  }
}

Section.propTypes = {
  title: PropTypes.string,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.logo,
    })
  ),
}
