import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/block-feature.module.scss"
import cardStyles from "src/styles/components/card.module.scss"
import Grid from "@material-ui/core/Grid"
import cx from "classnames"

export default function BlockFeature(props) {
  const {
    card,
    index,
    feature: { title, icon, description },
  } = props

  return (
    <Grid item xs={12} sm={6} md={4}>
      <div
        className={cx(
          styles.feature,
          { [cardStyles.cardContainer]: card },
          { [styles.cardContainer]: card },
          { [styles.plainContainer]: !card },
          `section-${index}`
        )}
      >
        {icon && <img src={icon} alt={title} key={title} />}
        <div className={styles.titleContainer}>
          <h4>{title}</h4>
        </div>
        <p>{description}</p>
      </div>
    </Grid>
  )
}

BlockFeature.propTypes = {
  card: PropTypes.bool,
  index: PropTypes.number,
  feature: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
  }),
}
